import { defineStore } from 'pinia';
import { useVariablesStore } from './variables';
import
{ getRegistrationInfo,
  getInitInfo,
  findFriend,
  getOccupations,
  getRunningClubs,
  saveRegistration,
  saveRelayRegistration,
  getRelayRegistration,
  removeRelayParticipant,
} from '@/api/register';
import maybePath from '@/utils/maybePath.js';
import moment from 'moment';
import getQuestionsDict from '@/pages/register/utils/getQuestionsDict';
import { userStorageInfo } from '@/composables/useUserInfo';
// import maybePath from '@/utils/maybePath.js';
const genderDict = {
  0: 'Мужчина',
  1: 'Женщина',
};

export const useRegisterStore = defineStore('register', {
  state: () => ({
    teamRegId: null,
    apiInitData: null,
    relayPosition: null,
    apiRegData: null,
    eventCode: null,
    raceCode: null,
    friend: null,
    isGift: false,
    alreadyRegistered: false,
    stageNumberIsRequired: false,
    teamName: null,
    isFriendFounded: false,
    clubs: null,
    visibleApiFields: [],
    requiredApiFields: [],
    isRegistrationClosed: false,
    registrationErrorText: null,
    currentStep: null,
    registrationId: null,
    occupations: null,
    currentUserType: null,
    friendNotFoundFields: ['FirstName', 'LastName', 'Birthday', 'Gender'],
    varsStore: useVariablesStore(),
  }),
  getters: {
    showTeamsField: (state) => state.apiRegData.CanViewTeamPromoCode,
    requiredProducts: (state) => {
      if (!state.isApiInitLoaded || !state.apiRegData?.RequiredProduct) return null;
      const reqProductAnswers = state.apiRegData.RequiredProduct.reduce((acc, product) => {
        const answers = (product.Answers || []).map((answer) => {
          answer.productId = product.ProductId;

          return answer;
        });

        return [...acc, ...answers];
      }, []);
      const questionsDict = getQuestionsDict(reqProductAnswers);

      return state.apiRegData.RequiredProduct.map((product) => {

        const questions = product.Questionnaire.Groups[0]?.Questions
          .filter((question) => question.Type === 'DropDownChoice')
          .map((question) => {
            if (questionsDict[question.Type]) {
              question.productId = product.Id;
              return questionsDict[question.Type](question);
            }
          }).filter(Boolean);

        return {
          questions,
          id: product.Id,
          title: product.Title,
          imageUrl: product.ImageUrl,
          fullInfo: product,
        };
      });
    },
    isRelay: (state) => state.apiInitData && state.apiInitData.Race?.IsRelay,
    validationErrors: (state) => state.apiRegData && state.apiRegData.ValidationErrors,
    isFriend: (state) => state.currentUserType === 'friend',
    getCurrentUserType: (state) => state.currentUserType,
    isApiInitLoaded: (state) => !!state.apiInitData && !!state.apiRegData,
    eventName: (state) => maybePath(state.apiInitData, 'Race.EventName'),
    disciplineName: (state) => maybePath(state.apiInitData, 'Race.DisciplineName'),
    raceName: (state) => maybePath(state.apiInitData, 'Race.RaceName'),
    admissions: (state) => {
      const admissionsDict = {
        self: maybePath(state, 'apiRegData.UserAdmissions'),
        friend: maybePath(state, 'friend.UserAdmissions'),
      };

      return admissionsDict[state.currentUserType] ?? [];
    },
    raceDateISO: (state) => {
      if (!state.isApiInitLoaded) return;

      const raceDate = state.apiInitData.Race.RaceDate || state.apiInitData.Race.EventEndDate;
      return moment(new Date(raceDate.Year, raceDate.Month - 1, raceDate.Day)).toISOString();
    },
    raceDate: (state) => {
      if (!state.isApiInitLoaded) return;

      const raceDate = state.apiInitData.Race.RaceDate || state.apiInitData.Race.EventEndDate;
      moment.locale('ru');
      return moment(new Date(raceDate.Year, raceDate.Month - 1, raceDate.Day)).format('LL').replace('г.', '');
    },
    importantText: (state) => {
      if (state.isFriend && state.friend) {
        return state.friend.Message;
      }

      return maybePath(state, 'apiRegData.Message');
    },
    questions: (state) => {
      if (!state.isApiInitLoaded) return;
      if (!state.apiRegData.Questionnaire) return [];

      const raceQuestions = state.apiRegData.Questionnaire.Groups.find((group) => {
        return group.Code === 'RaceQuestionnaire';
      });

      return raceQuestions ? raceQuestions.Questions : [];
    },
    terms: (state) => {
      const termsDict = {
        self: maybePath(state, 'apiRegData.Terms'),
        friend: maybePath(state, 'friend.Terms') ?? maybePath(state, 'apiRegData.Terms'),
      };

      const currentTerms = termsDict[state.currentUserType];

      if (!currentTerms) return {};

      return currentTerms
        .filter((term) => term.Visible)
        .reduce((acc, term) => {
          acc[term.Code] = term;
          return acc;
        }, {});
    },
    isWaitingForPayment: (state) => maybePath(state, 'apiInitData.IsWaitingForPayment'),
    visibleFields: (state) => {
      if (state.currentUserType === 'friend' && state.isFriendFounded) {
        return state.friend?.VisibleFields || state.visibleApiFields;
      }

      if (state.currentUserType === 'friend' && !state.isFriendFounded) {
        return [...state.friendNotFoundFields, ...(state.visibleApiFields || [])];
      }

      return maybePath(state, 'apiRegData.VisibleFields') || [];
    },
    requiredFields: (state) => {
      const additionalFields = state.isRelay ? ['FirstName', 'LastName'] : [];

      if (state.currentUserType === 'friend' && state.friend) {
        return [...(state.friend.RequiredFields || state.requiredApiFields || []), ...additionalFields];
      }

      if (state.currentUserType === 'friend' && !state.friend) {
        return [...state.friendNotFoundFields, ...(state.requiredApiFields || []), ...additionalFields];
      }

      return [...(maybePath(state, 'apiRegData.RequiredFields') || []), ...additionalFields];
    },
    userInfo: (state) => {
      if (!state.isApiInitLoaded) return;

      const userDict = {
        self: maybePath(state, 'apiRegData.User'),
        friend: maybePath(state, 'friend.User'),
      };

      const userObj = userDict[state.currentUserType];

      if (!userObj) return null;

      return {
        rawInfo: userObj,
        age: userObj.Age,
        email: userObj.Email,
        firstName: userObj.FirstName,
        lastName: userObj.LastName,
        fullName: [userObj.LastName, userObj.FirstName, userObj.Patronymic].filter(Boolean).join(' '),
        patronymic: userObj.Patronymic,
        phoneNumber: userObj.PhoneNumber,
        country: userObj.Country,
        occupation: userObj.Occupation,
        secondPhoneNumber: userObj.SecondPhoneNumber,
        club: userObj.RunningClub,
        gender: genderDict[userObj.Gender],
        address: {
          country: userObj.Country,
          city: userObj.City,
          house: userObj.Building,
          appartment: userObj.Appartment,
          street: userObj.Street,
          region: userObj.Region,
        },
      };
    },
  },
  actions: {
    setCurrentStep(step) {
      this.currentStep = step;
    },
    setRegistrationId(regId) {
      this.registrationId = regId;
    },
    async removeParticipant(position) {
      const { data, error } = await removeRelayParticipant(position, this.teamRegId);

      if (error) {
        console.error(error);
        return;
      }

      return data;
    },
    setRelayPosition(position) {
      this.relayPosition = position;
    },
    async getRelayRegistrationInfo() {
      const { data, error } = await getRelayRegistration(this.teamRegId);

      if (error) {
        console.error(error);
        return;
      }

      this.stageNumberIsRequired = data.StageNumberIsRequired;

      return data;
    },
    setStateTeamName(teamName) {
      this.teamName = teamName;
    },
    async setTeamName(teamName) {
      const { data, error } = await saveRelayRegistration(this.eventCode, this.raceCode, teamName);

      if (error) {
        console.error(error);
        return;
      }

      if (!data.Success) {
        return { error: { code: data.Error } };
      }

      this.setStateTeamName(teamName);
      this.teamRegId = data.TeamRegistrationId;

      return { error: null };
    },
    setIsGift(val) {
      this.isGift = val;
    },
    async saveRegistrationData(answers, userInfo, requiredProducts = [], promocode = '') {
      const regInfo = {
        User: userInfo,
        Answers: answers,
        EventCode: this.eventCode,
        RaceCode: this.raceCode,
        RegistrationId: this.registrationId,
        RelayPosition: this.relayPosition,
        IsFriend: this.isFriend,
        RequiredProduct: requiredProducts,
        PromoCode: promocode,
        Token: null,
        IsGift: this.isGift,
      };

      const { data, error } = await saveRegistration(regInfo);

      if (error) {
        return { errors: [error] };
      }

      if (!data.Success) {
        return {
          errorTitle: data.ErrorTitle,
          errors: data.ErrorMessages,
        };
      }

      this.registrationId = data.RegistrationId;
      userStorageInfo.registrationId = data.RegistrationId;
    },
    resetFriend() {
      this.friend = null;
    },
    async getClubs() {
      if (this.clubs) return this.clubs;

      const { error, data } = await getRunningClubs();
      if (error) return [];

      this.clubs = data.map((item) => ({
        id: item.Code,
        name: item.Text,
      }));

      return this.clubs;
    },
    async getOccupations() {
      if (this.occupations) return this.occupations;

      const { error, data } = await getOccupations();
      if (error) return [];

      this.occupations = data.map((item) => ({
        id: item.Code,
        name: item.Text,
      }));

      return this.occupations;
    },
    async init(eventCode, raceCode, cancelDraft) {
      this.eventCode = eventCode;
      this.raceCode = raceCode;

      if (this.isRegistrationClosed) return;

      await this.getApiInitData(eventCode, raceCode, cancelDraft);
      this.registrationId = this.apiInitData.RegistrationId;

      if (this.apiInitData.Race.IsRelay) {
        this.apiRegData = {};
        return;
      }

      return await this.getApiRegistrationData();
    },
    async findFriendByEmail(email) {
      if (this.friend) return this.friend;

      const { error, data } = await findFriend(this.eventCode, this.raceCode, email);

      if (error) {
        console.error(error);
        return;
      }

      if (data.ErrorMessages && data.ErrorMessages.length > 0) {
        return {
          error: {
            messages: data.ErrorMessages,
          },
        };
      }

      this.isFriendFounded = data.Found;
      data.User = data.User ? data.User : {};
      data.User.Email = email;

      this.friend = data;

      return { error: null };
    },
    /**
     * @param {string} type // user, friend
     */
    setCurrentUserType(type) {
      this.currentUserType = type;
    },
    async getApiInitData(eventCode, raceCode, cancelDraft) {
      const { data, error } = await getInitInfo(eventCode, raceCode, cancelDraft);

      if (error) {
        console.error(error);
        return;
      }

      if (data.Error) {
        this.handleApiErrors(data.Error, data);
      }

      if (data.Error === 2) {
        this.alreadyRegistered = true;
      }

      if (data.TeamRegistrationId) {
        this.teamRegId = data.TeamRegistrationId;
      }

      this.apiInitData = data;

      return data;
    },
    async getApiRegistrationData() {
      const { data, error } = await getRegistrationInfo(this.eventCode, this.raceCode, this.relayPosition);

      if (error) {
        console.error(error);
        return;
      }

      if (data.HttpStatus && data.HttpStatus === 404) {
        this.handleApiErrors(6, null);
        return;
      }

      if (data.Error) {
        this.handleApiErrors(data.Error, data);
      }

      if (this.requiredApiFields && this.requiredApiFields.length === 0) {
        this.requiredApiFields = data.RequiredFields;
      }

      if (this.visibleApiFields && this.visibleApiFields.length === 0) {
        this.visibleApiFields = data.VisibleFields;
      }

      this.setCurrentStep(data.RegistrationStep ? data.RegistrationStep : 1);

      if (data.IsFriend) {
        this.setCurrentUserType('friend');
      } else {
        this.setCurrentUserType('self');
      }

      this.apiRegData = data;

      return data;
    },
    handleApiErrors(errorNumber, data) {
      //   Undefined = 0,
      //   HasAnotherDraft = 1,
      //   AlreadyRegistered = 2,
      //   MultipleParticipationNotAllowed = 3,
      //   Redirect = 4,
      //   IsNotAvailable = 5,
      //   Closed = 6

      const handlersDict = {
        0: {
          action: () => {
            this.isRegistrationClosed = true;
            this.registrationErrorText = '<p class="h3 color-primary">Неизвестная ошибка</p>';
          },
        },
        1: {
          action: () => {
            this.registrationErrorText = '<p class="h3 color-primary">Вы уже регистрируетесь на другое событие</p>';
          },
        },
        2: {
          action: () => {
            this.currentUserType = 'friend';
          },
        },
        3: {
          action: () => {
            this.currentUserType = 'friend';
          },
        },
        4: {
          action: () => {
            if (window.location.hostname === 'localhost') return;
            window.location = data.RedirectUrl;
          },
        },
        5: {
          action: () => {
            this.isRegistrationClosed = true;
            this.registrationErrorText = '<p class="h3 color-primary">Регистрация недоступна</p>';
          },
        },
        6: {
          action: () => {
            this.isRegistrationClosed = true;
            this.registrationErrorText = '<p class="h3 color-primary">Регистрация закрыта</p>';
          },
        },
        7: {
          action: () => {
            this.isRegistrationClosed = true;
            this.registrationErrorText = `
              <p class="text-large-semibold">
                Заказ ожидает оплаты. Если в течение 1 часа после оплаты статус не изменится - обратитесь в
                <a
                  class="color-primary text-large-semibold"
                  href="mailto:${this.varsStore.supportEmail}">
                  службу поддержки
                </a>
              </p>
            `;
          }
        }
      };

      if (handlersDict[errorNumber]) handlersDict[errorNumber].action();
    }
  },
});
